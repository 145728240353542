import { Component, Input, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { CalandierDatapickerComponent, SmartTableDatepickerRenderComponent } from 'src/app/utils/calandier-datapicker/calandier-datapicker.component';
import { DataManagementService } from '../data-management.service';

@Component({
  selector: 'ngx-vehicules',
  templateUrl: './vehicules.component.html',
  styleUrls: ['./vehicules.component.scss']
})
export class VehiculesComponent implements OnInit {

  @Input() title: string;

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      matricule: {
        title: 'Matricule',
        type: 'string',
        filter: true
      },
      mark: {
        title: 'marque',
        type: 'string',
        filter: true
      },
      circulationAt: {
        title: 'Date MP',
        type: 'custom',
        renderComponent: SmartTableDatepickerRenderComponent,
        width: '250px',
        filter: true,
        sortDirection: 'desc',
        editor: {
          type: 'custom',
          component: CalandierDatapickerComponent,
        },
      },
    }
  };
  source: LocalDataSource = new LocalDataSource();

  constructor(public dataManagementService: DataManagementService) { }

  ngOnInit(): void {
    this.loadVehicules();
  }


  loadVehicules() {
    this.dataManagementService.vehicules = [];
    this.dataManagementService.findAllVehicules().subscribe(veh => {

      this.dataManagementService.vehicules = veh;

      this.source.load(this.dataManagementService.vehicules);
    })
  }

  onDeleteConfirm(event): void {
    if (window.confirm(`Are you sure you want to delete ${event.data.name}?`)) {
      this.dataManagementService.deleteVehicules(event.data.idVehicule).subscribe(
        res => {
          console.log(res);
          event.confirm.resolve();
          this.dataManagementService.loadVehicules();
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event): void {
    if (window.confirm(`Are you sure you want to add ${event.newData.name}?`)) {
      event.newData = {
        "matricule": event.newData.matricule,
        "mark": event.newData.mark,
        "circulationAt": event.newData.circulationAt,
      }
      this.dataManagementService.addVehicules(event.newData).subscribe(
        res => {
          event.confirm.resolve(event.newData);
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  onSaveConfirm(event): void {
    if (window.confirm(`Are you sure you want to edit ${event.newData.name}?`)) {
      var newData = {
        "idVehicule": event.newData.idVehicule,
        "matricule": event.newData.matricule,
        "mark": event.newData.mark,
        "circulationAt": event.newData.circulationAt,
      }
       this.dataManagementService.updateVehicules(newData).subscribe(
         res => {
           event.confirm.resolve(event.newData);
         }
       );
    } else {
      event.confirm.reject();
    }
  }
}