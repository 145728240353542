import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { StatsProgressBarData } from './data/stats-progress-bar';
import { UserData } from './data/users';
import { MockDataModule } from './mock/mock-data.module';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { UserService } from './mock/users.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { StateService } from './utils/state.service';

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
]

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  StateService
]

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
