import { Component, EventEmitter, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { MapService } from './map/map.service';
import 'leaflet-draw';

@Component({
  selector: 'ngx-leaflet',
  templateUrl: './leaflet.component.html',
  styleUrls: ['./leaflet.component.scss']
})
export class LeafletComponent {

  public baseMaps: any;
  public map: any
  public mapLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  isFullscreen = false;
  marker:any;

  constructor(private mapService: MapService) {
  }

  ngOnInit() {
      if(this.mapService.map != undefined && this.mapService.map != null) {
        this.mapService.map.off();
        this.mapService.map.remove();
      }
      this.mapConfig();
  }

  mapConfig() { 
    let defaultLayer = this.mapService.baseMaps.Fleet;
    var lat = 34;
    var lon = 10;
    let optionsSpec: any = {
      zoomControl: false,
      center: L.latLng(lat, lon),
      zoom: 6,
      minZoom: 3,
      maxZoom: 20,
      maxNativeZoom: 17,
      layers: [defaultLayer]
    }
    this.mapService.map = new L.Map('map', optionsSpec);
    this.mapService.map.invalidateSize(true);
    L.control.zoom({ position: 'topright' }).addTo(this.mapService.map);

  }

  toggleFullscreen() {
    if (this.isFullscreen) {
      document.getElementById("map").style.zIndex = "4";
      this.isFullscreen = false;
    } else {
      document.getElementById("map").style.zIndex = "15";
      this.isFullscreen = true;
    }
  }

}
