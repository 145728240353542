import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './signin/signin.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbProgressBarModule,
  NbSelectModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbToastrModule,
  NbWindowModule,
  NbButtonModule,
  NbSearchModule
} from '@nebular/theme';
import { ThemeModule } from './@theme/theme.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { LayoutService } from './@core/utils/layout.service';
import { AuthGuard } from './@core/utils/AuthGuard';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { NbIconModule, NbInputModule, NbTreeGridModule } from '@nebular/theme';
import { CoreModule } from './@core/core.module';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { CdkTableModule } from '@angular/cdk/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { PagesComponent } from './pages/pages.component';
import { DataManagementComponent } from './pages/data-management/data-management.component';
import { VehiculesComponent } from './pages/data-management/vehicules/vehicules.component';
import { TechniciensComponent } from './pages/data-management/techniciens/techniciens.component';
import { HomeComponent } from './pages/home/home.component';
import { CalandierDatapickerComponent, SmartTableDatepickerRenderComponent } from './utils/calandier-datapicker/calandier-datapicker.component';
import { PointClientsComponent } from './pages/data-management/point-clients/point-clients.component';
import { jqxSchedulerModule } from 'jqwidgets-ng/jqxscheduler';
import { PreventiveMaintenanceComponent } from './pages/maintenance/preventive-maintenance/preventive-maintenance.component';
import { CuratifMaintenanceComponent } from './pages/maintenance/curatif-maintenance/curatif-maintenance.component';
import { jqxComboBoxModule } from 'jqwidgets-ng/jqxcombobox';
import { NgxDaterangepickerModule } from '@qqnc/ngx-daterangepicker';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletComponent } from './utils/leaflet/leaflet.component';
import { MapService } from './utils/leaflet/map/map.service';
import { BarChartComponent } from './pages/home/bar-chart/bar-chart.component';
import { HistoryDateComponent } from './pages/history/history-date/history-date.component';
import { HistoryUserComponent } from './pages/history/history-user/history-user.component';
import { HistoryLiftComponent } from './pages/history/history-lift/history-lift.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Bar2ChartComponent } from './pages/home/bar2-chart/bar2-chart.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import {HistoryComponent} from "./pages/history/history.component";


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    PagesComponent,
    DataManagementComponent,
    VehiculesComponent,
    TechniciensComponent,
    HomeComponent,
    HistoryComponent,
    CalandierDatapickerComponent,
    SmartTableDatepickerRenderComponent,
    PointClientsComponent,
    PreventiveMaintenanceComponent,
    CuratifMaintenanceComponent,
    LeafletComponent,
    BarChartComponent,
    HistoryDateComponent,
    HistoryUserComponent,
    HistoryLiftComponent,
    Bar2ChartComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule,
    FormsModule,
    BrowserModule,
    NgxDaterangepickerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxDaterangepickerModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbButtonModule,
    ThemeModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    FormsModule,
    Ng2SmartTableModule,
    NbCardModule,
    NbTreeGridModule,
    NbIconModule,
    NbInputModule,
    NbProgressBarModule,
    CoreModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxChartsModule,
    ChartModule,
    NbCardModule,
    NbSelectModule,
    HighchartsChartModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbModule,
    NgxImageGalleryModule,
    NgxQRCodeModule,
    NbThemeModule,
    NbTabsetModule,
    CdkTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatMomentDateModule,
    NbSearchModule,
    jqxSchedulerModule,
    jqxComboBoxModule,
    LeafletModule,
    Ng2SearchPipeModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatIconModule
  ],

  exports: [

  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    LayoutService,
    AuthGuard,
    JwtHelperService,
    MapService,
  ],
  entryComponents: [
    SmartTableDatepickerRenderComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
