<nb-layout windowMode>
    <nb-layout-header fixed>
      <ngx-header></ngx-header>
    </nb-layout-header>

    <nb-sidebar class="menu-sidebar" state="compacted" tag="menu-sidebar" responsive start>
      <ng-content select="nb-menu"></ng-content>
    </nb-sidebar>

    <nb-layout-column (click)="dostaff()">
      <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>

    <nb-layout-footer fixed>
      <ngx-footer></ngx-footer>
    </nb-layout-footer>
  </nb-layout>