import { Component } from '@angular/core';
import {DataManagementService} from "./pages/data-management/data-management.service";


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  title = 'stas-front';

  constructor(private dataManagementService: DataManagementService) {
    this.dataManagementService.init();
  }
}
