import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { CalandierDatapickerComponent, SmartTableDatepickerRenderComponent } from 'src/app/utils/calandier-datapicker/calandier-datapicker.component';
import { DataManagementService } from '../data-management.service';

@Component({
  selector: 'ngx-point-clients',
  templateUrl: './point-clients.component.html',
  styleUrls: ['./point-clients.component.scss']
})
export class PointClientsComponent implements OnInit {


  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    sort: {
      pointer: 'codeClient',
      direction: 'desc'
    },
    columns: {
      codeClient: {
        title: 'Code',
        type: 'string',
        filter: true,
        width: '8px'
      },
      name: {
        title: 'Client',
        type: 'string',
        filter: true,
        width: '15px'
      },
      address: {
        title: 'Adresse',
        type: 'string',
        filter: true
      },
      nbVisit: {
        title: 'Nb visite',
        type: 'number',
        filter: true,
        width: '10px'
      },
      date: {
        title: 'Date',
        type: 'custom',
        renderComponent: SmartTableDatepickerRenderComponent,
        width: '25px',
        filter: true,
        sortDirection: 'desc',
        editor: {
          type: 'custom',
          component: CalandierDatapickerComponent,
        },
      },
      emails: {
        title: 'Email',
        type: 'string',
        filter: true
      },
      phone: {
        title: 'Telephone',
        type: 'number',
        filter: true
      },
    }
  };
  source: LocalDataSource = new LocalDataSource();

  constructor(private datePipe: DatePipe, public dataManagementService: DataManagementService) { }

  ngOnInit(): void {
    this.loadClients();
  }

  loadClients() {
    this.dataManagementService.pointClients = [];
    this.dataManagementService.findAllPointClients().subscribe(cls => {

      this.dataManagementService.pointClients = cls;
      this.dataManagementService.pointClients.sort((e1,e2)=>e1.codeClient-e2.codeClient)
      this.source.load(this.dataManagementService.pointClients);
    })
  }

  onDeleteConfirm(event): void {
    if (window.confirm(`Are you sure you want to delete ${event.data.name}?`)) {
      this.dataManagementService.deletePointClients(event.data.id).subscribe(
        res => {
          console.log(res);
          event.confirm.resolve();
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event): void {
    if (window.confirm(`Are you sure you want to add ${event.newData.name}?`)) {
      event.newData = {
        "codeClient": event.newData.codeClient,
        "name": event.newData.name,
        "emails": event.newData.emails,
        "phone": event.newData.phone,
        "address": event.newData.address,
        "ville": event.newData.ville,
        "nbVisit": event.newData.nbVisit,
        "dateIntervention": event.newData.dateIntervention,
      }
      this.dataManagementService.addPointClients(event.newData).subscribe(
        res => {
          event.confirm.resolve(event.newData);
          this.loadClients();
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  onSaveConfirm(event): void {
    if (window.confirm(`Are you sure you want to edit ${event.newData.name}?`)) {
      var newData = {
        "id": event.newData.id,
        "codeClient": event.newData.codeClient,
        "name": event.newData.name,
        "emails": event.newData.emails,
        "phone": event.newData.phone,
        "address": event.newData.address,
        "ville": event.newData.ville,
        "nbVisit": event.newData.nbVisit,
        "dateIntervention": event.newData.dateIntervention,
      }
       this.dataManagementService.updatePointClients(newData).subscribe(
         res => {
           event.confirm.resolve(event.newData);
         }
       );
    } else {
      event.confirm.reject();
    }
  }

}

