import { EventEmitter, Injectable } from '@angular/core';
import * as P from 'leaflet'
import Marker = P.Marker;

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public baseMaps: any;
  public map: any;
  public mapLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  markers = new Map();

  marker:any;
  myIcon = P.icon({
    iconUrl: 'assets/images/lift.png',
    iconRetinaUrl: 'my-icon@2x.png',
    iconSize: [60, 60]
  });

  currentUserId;
  constructor() {
    this.currentUserId = localStorage.getItem('id');
    this.baseMaps = {
      Fleet: P.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
        maxNativeZoom: 17,
        attribution:
          '&copy; <a href="#">Fleet</a>, Tiles courtesy of <a href="http://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>'
      })
    };

  }
  
  
  addMarker(id: number, lat: number, lon: number, popupText: string) {
    // ******************************  init the RT marker !!! ******************************
    let marker = this.markers.get(id);
    if (!marker) {
      marker = new Marker([lat, lon], { icon: this.myIcon });
      marker.on("mouseover", () => {
        marker.openPopup();
      });
      let popup = popupText;
      marker.bindPopup(popup);
    }
    marker.addTo(this.map);
    this.map.invalidateSize(true);

    this.markers.set(id, marker);
  }

}
