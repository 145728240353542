<div class="width-50 ">
    <nb-card>
        <nb-card-body>

          <div class="row">
            <div class="col-md-12">
              <nb-card class="inline-form-card">
                <nb-card-body>
                  <form style="display: flex; flex-direction: row; justify-content: space-evenly; ">
                    <input class="w-25" outline status="danger" type="text" [(ngModel)]="searchText" (keyup)="filterLifts()"
                           fullWidth nbInput placeholder="recherche assenceur" name="searchlift">


                    <button nbButton outline status="danger">{{filtredLifts?.length}}</button>

                    <nb-select class="w-25" outline status="danger" fullWidth placeholder="Affiche assenceur" name="afflift" [(ngModel)]="selectedLift">
                      <nb-option *ngFor="let l of filtredLifts" [value]="l">
                        {{l.codeClient}} / {{l.numCasne}} / {{l.name}}
                      </nb-option>
                    </nb-select>
                    <button nbButton style="background-color: #ec1c24; color: #ffffff;"
                            nbSpinnerStatus="warning" (click)="refresh()" nbSpinnerStatus="warning">Recherche</button>

                  </form>
                </nb-card-body>
              </nb-card>
            </div>
          </div>
            <div class="row">
<!--                <div class="col-md-6">-->

<!--                    <nb-select status="danger" [(selected)]="selectedLift" class=" pointer w-100">-->
<!--                        <nb-option *ngFor="let lift of dataManagementService?.lifts" [value]="lift">{{dataManagementService.formatLiftName(lift)}}-->
<!--                        </nb-option>-->
<!--                    </nb-select>-->
<!--                </div>-->

            </div>

        </nb-card-body>
    </nb-card>
</div>

<nb-card status="danger">
    <nb-card-header style="color: #ffffff; text-align: center; font-size: 30px; background-color: #ec1c24;">
        Historique par Ascenseur
    </nb-card-header>

    <nb-card-body>
        <ng2-smart-table [settings]="settings" [source]="source">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>
