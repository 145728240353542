import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PointClient } from 'src/app/data/data';
import { MapService } from 'src/app/utils/leaflet/map/map.service';
import { DataManagementService } from '../data-management/data-management.service';

@Component({
  selector: 'ngx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  id: any;
  pointClient: PointClient;
  marker;
  constructor(public dataManagementService: DataManagementService, private mapService: MapService, private router:Router) { }

  ngOnInit(): void {
    this.loadPointsClients();
    this.id = setInterval(() => {
      this.loadPointsClients();
    }, 1000 * 60);

  }
  ngAfterViewInit() {

    this.updateMarker();
  }


  loadPointsClients() {
    this.dataManagementService.findAllPointClients().subscribe(res => {
      this.dataManagementService.pointClients = res;
      res.forEach(pc => {
        this.mapService.addMarker(pc.id, pc.coordinate.lat, pc.coordinate.lng, this.getPopupContent(pc));
      });
    })
  }


  updateMarker() {
    // if (pc.coordinate.lat && pc.coordinate.lng)
    // this.mapService.addMarker(pc.id, pc.coordinate.lat, pc.coordinate.lng, this.getPopupContent(pc));

  }

  getPopupContent(pc: PointClient) {
    return ` <div class="leaflet-popup-content m-0" style="font-family: sans-serif;">
                <h5 class="mb-0">
                  <span class="badge pull-right" style="color: #ffffff; background-color: #ec1c24;">
                    ${pc.name ? pc.name.toUpperCase() : pc.id} 
                  </span>
                
                </h5>
                <div class="d-flex justify-content-between align-items-center mt-2">
                  <span title="altitude" class="font-weight-bold">Altitude: ${pc.coordinate.lat.toFixed(4) }/ ${pc.coordinate.lng.toFixed(4)} </span>
                </div>
                <hr class="my-2">
                <span title="Description">${pc.address}</span>
            </div>`;
  }


  goToPointClient() {
    this.router.navigate(["/pages/pointClients"]);
  }

}
