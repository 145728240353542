import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.scss']
})
export class DataManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
