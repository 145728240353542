import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { dns } from '../global.config';
import { Credentials } from './credentials';
import { Observable } from 'rxjs';
import { createAuthorizationHeader } from '../@core/utils/headers';
import { User } from '../data/data';

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(private _http: HttpClient) { }

  login(credentials: Credentials): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true"
    });
    return this._http
      .post(dns + 'authenticateadmin', credentials, { headers: headers });
  }

  logout(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'logout' , { headers: headers });
  }

  public isRootAdmin() {
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser != null) {
      for (let i = 0; i < currentUser.authorities.length; i++) {
        if (currentUser && currentUser.authorities[i].id == 1) {
          return true;
        }
        return false;
      }
    }
  }



}
