<div class="limiter">
  <div class="container-login100">

    <div class="login">

      <header class="login__header">
        <img class="imgclass" src="../../assets/images/log_stas.png" />
      </header>

      <form action="#" class="login__form" method="POST">

        <div>
          <input type="email" id="email" name="email" [(ngModel)]="credentials.username" #username="ngModel"
            placeholder="Username">
        </div>

        <div>
          <input type="password" id="password" placeholder="Password" [(ngModel)]="credentials.password"
            #password="ngModel" name="password">
        </div>

        <div>
          <input class="button" type="submit" value="Se connecte" (click)="login()">
        </div>

      </form>

    </div>

  </div>
</div>
<div  class="created-by">
  <span>Created with <span style="color:red">♥</span> by <b>ACCENT</b> 2022</span>
</div>