import { Component, OnInit } from '@angular/core';
import * as Highcharts5 from 'highcharts';

@Component({
  selector: 'ngx-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  options: any;
  constructor() { }

  ngOnInit(): void {
    this.configHig();
  }

  configHig() {
    this.options = {
      chart: {
        type: 'column'
      },
      title: null,
      subtitle: null,
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        title: {
          text: null
        }

      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%'
          }
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
      },

      series: [
        {
          name: "Browsers",
          colorByPoint: true,
          data: [
            {
              name: "lift 1",
              y: 7,
            },
            {
              name: "lift 2",
              y: 5,
            },
            {
              name: "lift 3",
              y: 2,
            },
            {
              name: "lift 4",
              y: 2,
            },
            {
              name: "lift 5",
              y: 2,
            },
            {
              name: "lift 6",
              y: 1,
            },

          ]
        }
      ],

    }

    Highcharts5.chart('container', this.options);
  }

}
