<div class="row">
    <div class="col-md-12">
        <nb-card class="inline-form-card">
            <nb-card-body>
                <form class="form-inline">
                    <nb-select outline status="danger" fullWidth placeholder="Select Technicien"
                        [(ngModel)]="selectedUser" (selectedChange)="refreshScheds()" name="selectuser">
                        <nb-option *ngFor="let user of dataManagementService.users " [value]="user">
                            {{user.firstname}} {{user.lastname}}
                        </nb-option>
                    </nb-select>

                    <input outline status="danger" type="text" [(ngModel)]="searchText" (keyup)="filterLifts()"
                        fullWidth nbInput placeholder="recherche assenceur" name="searchlift">


                    <button nbButton outline status="danger">{{filtredLifts?.length}}</button>

                    <nb-select outline status="danger" fullWidth placeholder="Affiche assenceur" name="afflift" [(ngModel)]="selectedLift">
                        <nb-option *ngFor="let l of filtredLifts" [value]="l">
                            {{l.codeClient}} / {{l.numCasne}} / {{l.name}}
                        </nb-option>
                    </nb-select>

                </form>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="row">
    <jqxScheduler [theme]="'light'" #scheduler [width]="getWidth" [height]="490" [date]="date" [source]="dataAdapter"
        [showLegend]="true" [localization]="localization" (onAppointmentAdd)="appointmentAdd($event)"
        (onAppointmentDelete)="appointmentDelete($event)" [editDialogOpen]="editDialogOpen"
        [editDialogClose]="editDialogClose" [editDialogCreate]="editDialogCreate" (onDateChange)="DateChange($event)"
        (onAppointmentChange)="appointmentChange($event)" [view]="'monthView'" [views]="views"
        [appointmentDataFields]="appointmentDataFields">
    </jqxScheduler>
</div>
