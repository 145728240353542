import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxSchedulerComponent } from 'jqwidgets-ng/jqxscheduler';
import { Lift, Maintenance, User } from 'src/app/data/data';
import { DataManagementService } from '../../data-management/data-management.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ngx-curatif-maintenance',
  templateUrl: './curatif-maintenance.component.html',
  styleUrls: ['./curatif-maintenance.component.scss']
})
export class CuratifMaintenanceComponent implements OnInit {
  isReadonly = false;
  editRecurringAppointmentOccurrenceElement: HTMLElement;
  selectedUser: User;
  selectedLift: Lift;
  filtredLifts: Lift[];
  liftsData: String[] = [];

  @ViewChild('singleSelect') singleSelect: MatSelect;
  public bankCtrl: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  //currentMaintenances: Maintenance[] = [];
  currentMaintenance: Maintenance = new Maintenance();
  currentUserMaintenances: Maintenance[] = [];
  from: Date = new Date();
  to: Date = new Date();

  lastDropDown: any;
  searchText: any;
  id: any;
  @ViewChild('scheduler') scheduler: jqxSchedulerComponent;

  constructor(public dataManagementService: DataManagementService, private toastr: ToastrService) {
    this.dataManagementService.componentName = "Reparation / Dépannage";
  }

  ngOnInit(): void {
    this.dataManagementService.init();
       this.id = setInterval(() => {
      this.loadMaintenancesCuratifs(this.selectedUser.id);
    }, 20000);

  }

  refreshScheds() {
    this.loadMaintenancesCuratifs(this.selectedUser.id);
  }


  loadMaintenancesCuratifs(userId) {
    let date = new Date();
    this.from = new Date(date.getFullYear(), date.getMonth(), 1);
    this.to = new Date(this.from.getTime() + (1000 * 60 * 60 * 24 * 60));
    this.dataManagementService.getMaintenancesByUserDateCuratifs(userId, { "startDate": this.from.getTime(), "endDate": this.to.getTime() }).subscribe(maintenances => {
      this.currentUserMaintenances = maintenances.filter(e => e.userId == this.selectedUser.id);
      this.currentUserMaintenances.forEach(e => {
        let d = new Date();
        d.setHours(0, 0, 0, 0);
        if (e.status == 0 && new Date(e.start) >= d) {
          e.background = "rgba(255, 0, 0, 0.8)";//red
        } else if ((e.status == 0 && new Date(e.start) < d ) || e.status == 1) {
          e.background = "rgba(0, 0, 255, 0.8)";// blue
        } else if (e.status == 2) {
          e.background = "rgba(0, 255, 0, 255.8)";// green
        } else if (e.status == 3) {
          e.background = "rgba(80, 80, 80, 0.8)";// grey
        } else if (e.status == 4) {
          e.background = "rgba(255 , 156, 0, 0.8)";// orange
        }

      });
      this.source.localData = this.currentUserMaintenances;
      this.dataAdapter = new jqx.dataAdapter(this.source);
    })
  }

  getWidth(): any {
    if (document.body.offsetWidth < 900) {
      return '90%';
    }

    return '1250';
  }

  date: any = new jqx.date(new Date());
  source: any =
    {
      dataType: 'array',
      dataFields: [
        { name: 'id', type: 'string' },
        { name: 'idMaintenance', type: 'number' },
        { name: 'about', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'background', type: 'string' },
        { name: 'start', type: 'date', format: 'yyyy-MM-dd HH:mm' },
        { name: 'end', type: 'date', format: 'yyyy-MM-dd HH:mm' },
        { name: 'recurrenceRule', type: 'string' },
        { name: 'calendar', type: 'string' },

      ],
      id: 'id',
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  localization: any = {
    firstDay: 1,
    lastDay: 31,
    days: {
      // full day names
      names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi',
        'Jeudi', 'Vendredi', 'Samedi'],
      // abbreviated day names
      namesAbbr: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      // shortest day names
      namesShort: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"]
    },

    months: {
      // full month names (13 months for lunar calendards -- 13th month should be "" if not lunar)
      names: [
        'Janvier', 'Février', 'Mars', 'Avril',
        'Mai', 'Juin', 'Juillet', 'Août',
        'Septembre', 'Octobre', 'Novembre', 'Décembre', ''
      ],
      // abbreviated month names
      namesAbbr: ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc', ''],
    },
    loadString: "Chargement...",
    todayString: "aujourd'hui",
    dayViewString: "Jour",
    weekViewString: "Semaine",
    monthViewString: "Mois",
    editRecurringAppointmentDialogSeriesString: "Modifier le plan",
    editRecurringAppointmentDialogContentString: "Voulez-vous modifier le plan?",
    editDialogTitleString: "Modifier le Plan",
    editDialogCreateTitleString: "Ajouter un plan",
    contextMenuEditAppointmentString: "Modifier le Plan",
    contextMenuCreateAppointmentString: "Ajouter un Plan",
    editDialogSubjectString: "Titre",
    editDialogFromString: "Debut",
    editDialogToString: "Fin",
    editDialogColorString: "Couleur",
    editDialogSaveString: "Enregistrer",
    editDialogDeleteString: "Supprimer",
    editDialogCancelString: "Annuler",
  };

  appointmentDataFields: any =
    {
      from: 'start',
      to: 'end',
      id: 'id',
      idMaintenance: 'idMaintenance',
      description: 'about',
      subject: 'name',
      background: 'background',
      recurrencePattern: "recurrenceRule",
    };

  views: any[] =
    [
      'monthView'
    ];

  DateChange(event: any): void {
    let args = event.args;
    let date = args.date; let from = args.from; let to = args.to;
    // console.log("from " + from + " to " + to);
    this.from = from.to
    Date(); this.to = to.toDate();
  }

  editDialogCreate = (dialog, fields, editAppointment) => {
    let maintenance = null;
    if (editAppointment)
      maintenance = this.currentUserMaintenances.find(e => e.id == editAppointment.id);
    this.liftsData = [];
    let selectedItem = "";
    let lifts = [];
    if (this.filtredLifts && this.filtredLifts.length > 0)
      lifts = this.filtredLifts;
    else
      lifts = this.dataManagementService.lifts;
    lifts.sort((e, f) => e.codeClient - f.codeClient);
    lifts.forEach(e => {
      this.liftsData.push(e.codeClient + '-' + e.numCasne + '-' + e.name);
      if (e.codeClient == maintenance?.liftId)
        selectedItem = e.codeClient + '-' + e.numCasne + '-' + e.name;
    });
    let selectedid = 0;
    if (maintenance)
      selectedid = this.liftsData.indexOf(selectedItem);
    let dropdownElement = document.createElement('DIV');
    dropdownElement.id = "list";
    //fields.color.hide();
    if (fields) {
      fields.description[0].parentNode.appendChild(dropdownElement);
      // this.lastDropDown = jqwidgets.createInstance("#list", "jqxDropDownList", { selectedIndex: selectedid, source: this.liftsData, width: '100%', height: 30, dropDownHeight: 100 });
    }
  }


  editDialogOpen = (dialog, fields, editAppointment) => {
    let maintenance = null;
    if (editAppointment)
      maintenance = this.currentUserMaintenances.find(e => e.id == editAppointment.id);
    this.liftsData = [];
    let selectedItem = "";
    let lifts = [];
    if (this.filtredLifts && this.filtredLifts.length > 0)
      lifts = this.filtredLifts;
    else
      lifts = this.dataManagementService.lifts;
    lifts.sort((e, f) => e.codeClient - f.codeClient);
    lifts.forEach(e => {
      this.liftsData.push(e.codeClient + '-' + e.numCasne + '-' + e.name);
      if (e.codeClient == maintenance?.liftId)
        selectedItem = e.codeClient + '-' + e.numCasne + '-' + e.name;
    });
    let selectedid = 0;
    if (maintenance)
      selectedid = this.liftsData.indexOf(selectedItem);
    let dropdownElement = document.createElement('DIV');
    dropdownElement.id = "list";
    //fields.color.hide();
    if (fields) {
      fields.description[0].parentNode.appendChild(dropdownElement);
      // this.lastDropDown = jqwidgets.createInstance("#list", "jqxDropDownList", { selectedIndex: selectedid, source: this.liftsData, width: '100%', height: 30, dropDownHeight: 100 });
    }
    if (fields) {
      let maintenance = null;
      let selectIndex = 0;
      if (editAppointment) {
        maintenance = this.currentUserMaintenances.find(e => e.id == editAppointment.id);
        for (let i = 0; i < this.liftsData.length; i++) {
          if (this.liftsData[i].startsWith(maintenance.liftId + "-"))
            selectIndex = i;
        }
      }
      if (this.lastDropDown)
        this.lastDropDown.selectIndex(selectIndex);
      // hide repeat option
      fields.repeatContainer.hide();
      fields.repeatEndPanelContainer.hide();
      // hide exception option
      fields.resetExceptionsContainer.hide();
      // hide status option
      fields.statusContainer.hide();
      // hide timeZone option
      fields.timeZoneContainer.hide();
      fields.subjectLabel.html("Title");
      fields.locationContainer.hide();
      fields.fromLabel.html("Start");
      fields.subjectContainer.hide();
      fields.subject.readOnly = true;
      fields.toLabel.html("End");
      fields.colorContainer.hide();
      fields.resourceLabel.html("Calendar");
      //fields.resourceContainer.hide();
      fields.allDayContainer.hide();
      fields.repeatPanel.hide();
      fields.resetExceptionsLabel.hide();
      fields.resetExceptions.hide();
      fields.colorLabel.hide();
    }
  }




  editDialogClose = (dialog, fields, editAppointment) => {
    if (fields && this.selectedUser && this.lastDropDown) {
      let selectedId = this.lastDropDown.getSelectedItem().value.substring(0, this.lastDropDown.getSelectedItem().value.indexOf("-"));
      let selectedName = this.lastDropDown.getSelectedItem().value.substring(this.lastDropDown.getSelectedItem().value.indexOf("-") + 1);
      // console.log(selectedId);
      this.selectedLift = this.dataManagementService.getLiftById(selectedId);
      fields.subject.val(selectedName + "/" + this.selectedUser.firstname);
    }

  }

  appointmentDelete(event: any): void {
    this.dataManagementService.deleteMaintenanceCuratif(event.args.appointment.originalData.idMaintenance).subscribe(tmp => {
      this.toastr.success("Modification avec succes");
      this.refreshScheds();
    }, error => {
      this.toastr.error("Erreur lors de la modification");
    }
    );
  }


  appointmentChange(event: any): void {
    // let selectedId = this.lastDropDown.getSelectedItem().value.substring(0, this.lastDropDown.getSelectedItem().value.indexOf("-"));
    // this.selectedLift = this.dataManagementService.getLiftById(selectedId);
    let idMaintenance = this.dataAdapter._source.localData.find(e => e.id == event.args.appointment.originalData.id).idMaintenance;
    let maintenance = this.currentUserMaintenances.find(e => e.idMaintenance == idMaintenance);

    maintenance.about = event.args.appointment.originalData.about;
    maintenance.background = event.args.appointment.originalData.background;
    maintenance.end = event.args.appointment.originalData.end;
    maintenance.start = event.args.appointment.originalData.start;
    maintenance.name = event.args.appointment.originalData.name;
    maintenance.id = event.args.appointment.originalData.id;
    maintenance.liftId = this.selectedLift.id;
    maintenance.name = this.selectedLift.codeClient + '-' + this.selectedLift.numCasne + '-' + this.selectedLift.name + "/" + this.selectedUser.firstname;
    this.dataManagementService.updateMaintenanceCuratif(maintenance).subscribe(tmp => {
      this.toastr.success("Modification avec succes");
      this.refreshScheds();
    }, error => {
      this.toastr.error("Erreur lors de la modification");
    }
    );
  };


  appointmentAdd(event: any): void {
    // let selectedId = this.lastDropDown.getSelectedItem().value.substring(0, this.lastDropDown.getSelectedItem().value.indexOf("-"));
    // this.selectedLift = this.dataManagementService.getLiftById(selectedId);

    let maintenance = event.args.appointment.originalData;
    maintenance.userId = this.selectedUser.id;
    maintenance.liftId = this.selectedLift.id;
    maintenance.status = 0;

    if (maintenance.name == "")
      maintenance.name = this.selectedLift.codeClient + '-' + this.selectedLift.numCasne + '-' + this.selectedLift.name + "/" + this.selectedUser.firstname;
    this.currentMaintenance = maintenance;
    this.dataManagementService.addMaintenanceCuratif(this.currentMaintenance).subscribe(tmp => {
      this.toastr.success("Ajoute avec succes");
      this.refreshScheds();
    }), error => {
      this.toastr.error("Erreur lors d'ajouter");
    }

  };

  filterLifts() {
    if (!this.searchText || this.searchText == "")
      this.filtredLifts = [];
    else {
      if (isNaN(this.searchText)) {
        this.filtredLifts = this.dataManagementService.lifts.filter(e => e.name.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1);
      } else {
        this.filtredLifts = this.dataManagementService.lifts.filter(e => e.codeClient == this.searchText);
      }
    }
  }


  buttonFilterLift() {
    if (this.filtredLifts.length == 0) {
      return 0;
    } else {
      return this.filtredLifts.length;
    }
  }
}
