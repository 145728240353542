<nb-card status="danger">
    <nb-card-header style="color: #ffffff; text-align: center; font-size: 30px; background-color: #ec1c24;">
      Liste des clients
    </nb-card-header>
  
    <nb-card-body>
      <ng2-smart-table [settings]="settings" [source]="source"  (deleteConfirm)="onDeleteConfirm($event)"
      (createConfirm)="onCreateConfirm($event)" (editConfirm)="onSaveConfirm($event)">
      </ng2-smart-table>
    </nb-card-body>
  </nb-card>