import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { DataManagementService } from '../data-management.service';

@Component({
  selector: 'ngx-techniciens',
  templateUrl: './techniciens.component.html',
  styleUrls: ['./techniciens.component.scss']
})
export class TechniciensComponent implements OnInit {

  loading: boolean;
  authories: any[] = ["administrateur", "technicien", "ouvrier", "agent"];

  source: LocalDataSource = new LocalDataSource();
  settings = {
    pager: {
      perPage: 5,
    },
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

    columns: {
      username: {
        title: "Usename",
        type: 'string',
        filter: true
      },
      rawPassword: {
        title: "Password",
        type: 'string',
        filter: true
      },
      firstname: {
        title: "Nom",
        type: 'string',
        filter: true
      },
      lastname: {
        title: "Prémon",
        type: 'string',
        filter: true
      },
      phone: {
        title: "Telephone",
        type: 'string',
        filter: true
      },
      email: {
        title: "Emails",
        type: 'string',
        filter: true
      },
      authorityName: {
        title: "Authority",
        editor: {
          type: 'list',
          config: {
            selectText: 'Authority',
            list: this.authories
          }
        },
        filter: {
          type: 'list',
          config: {
            selectText: 'Authority',
            list: this.authories
          }
        }
      },

    }
  };

  constructor(public dataManagementService: DataManagementService) {
  }

  ngOnInit(): void {
    this.dataManagementService.eventDetails.subscribe(item => {
      this.init();
    })
    if (this.dataManagementService.initAuthorties())
      this.init();
  }
  init() {
    this.loadUser();
    this.authories = [];

    for (let i = 0; i < this.dataManagementService.authorties.length; i++) {
      this.authories.push({
        value: this.dataManagementService.authorties[i].label,
        title: this.dataManagementService.authorties[i].label
      });
    }

  }

  loadUser() {
    this.loading = true;
    this.dataManagementService.users = [];
    this.dataManagementService.findAllUsers().subscribe(users => {

      users.forEach(element => {
        users.installationName = element.installationName;
      });
      for (let i = 0; i < users.length; i++) {
        users[i].authorityName = this.checkAuth(users[i].authorities);

      }
      this.dataManagementService.users = users;
      this.source.load(this.dataManagementService.users);
      this.loading = true;
    }, () => {
      this.loading = false;
    })
  }


  onDeleteConfirm(event): void {
    if (window.confirm(`Are you sure you want to delete ${event.data.name}?`)) {
      this.dataManagementService.deleteUsers(event.data.id).subscribe(
        res => {
          console.log(res);
          event.confirm.resolve();
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event): void {
    if (window.confirm(`Are you sure you want to add ${event.newData.name}?`)) {
      event.newData = {
        "firstname": event.newData.firstname,
        "lastname": event.newData.lastname,
        "phone": event.newData.phone,
        "username": event.newData.username,
        "rawPassword": event.newData.rawPassword,
        "email": event.newData.email,
        "authorityName": event.newData.authorityName,
        "authorities": [],
      }
      this.dataManagementService.addUsers(event.newData).subscribe(
        res => {
          event.confirm.resolve(event.newData);
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  onSaveConfirm(event): void {
    if (window.confirm(`Are you sure you want to edit ${event.newData.name}?`)) {
      var newData = {
        "id": event.newData.id,
        "firstname": event.newData.firstname,
        "lastname": event.newData.lastname,
        "phone": event.newData.phone,
        "username": event.newData.username,
        "rawPassword": event.newData.rawPassword,
        "email": event.newData.email,
        "authorityName": event.newData.authorityName,
        "authorities": [],
      }
      this.dataManagementService.updateUsers(event.newData).subscribe(
        res => {
          event.confirm.resolve(event.newData);
        }
      );
    } else {
      event.confirm.reject();
    }
  }

  checkAuth(authorities: any[]): string {
    let auths: String[] = [];
    authorities.forEach(auth => {
      auths.push(auth["name"]);
    });
    if (auths.includes("ROLE_TECHNICIAN"))
      return "technicien";
    if (auths.includes("ROLE_ADMIN"))
      return "administrateur";
    if (auths.includes("ROLE_OUVRIER"))
      return "ouvrier";
    if (auths.includes("ROLE_AGENT"))
      return "agent";
    if (auths.length == 0)
      return "none";
    return "custom";
  }

}

