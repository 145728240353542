<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a style="color: #ff4f33;" class="logo" href="#" (click)="navigateHome()">
      <img src="../../../../assets/images/logo-light.png"  style="width: 40%;"/></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item" (click)="logout()" icon="log-out-outline"> </nb-action>
  </nb-actions>
</div>