import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { version } from '../global.config';
import { Credentials } from './credentials';
import { SigninService } from './signin.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  version = '';
  credentials: Credentials;
  constructor(private router: Router, private signinService: SigninService, private toastr: ToastrService) {
    this.version = version;
    this.credentials = new Credentials();
  }

  ngOnInit(): void {
  }

  login() {

    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    if (localStorage.getItem('currentUser')) {
      localStorage.removeItem('currentUser');
    }
    if (localStorage.getItem('id')) {
      localStorage.removeItem('id');
    }

    this.signinService.login(this.credentials).subscribe(currentUser => {
      localStorage.setItem('token', currentUser.token);
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      localStorage.setItem('id', currentUser.id);
      this.router.navigate(['/pages']);

    }, (error) => {
      if (error.status == 422) {
        this.toastr.error('Login or password invalid ! ', 'Alert!', {
          closeButton: true,
          positionClass: 'toast-bottom-right',
          timeOut: 10000
        });
      }
    });
  }
}

