import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/pages/home',
  },
  {
    title: 'Gestion de données',
    icon: 'settings-2-outline',
    children: [
      {
        title: 'Vehicules',
        icon: 'car-outline',
        link: '/pages/vehicules',
      },
      {
        title: 'Techniciens',
        icon: 'person-add-outline',
        link: '/pages/techniciens',
      },
      {
        title: 'Clients',
        icon: 'people-outline',
        link: '/pages/pointClients',
      },
    ]
  },
  {
    title: 'Gestion de maintenances',
    icon: 'settings-2-outline',
    children: [
      {
        title: 'Entretien',
        icon: 'home-outline',
        link: '/pages/preventive',
      },

      {
        title: 'Réparation / Depannage',
        icon: 'home-outline',
        link: '/pages/curatif',
      },

    ]
  },
  {
    title: 'Historique',
    icon: 'home-outline',
    children: [
      {
        title: 'Historique ',
        icon: 'home-outline',
        link: '/pages/historyDate',
      },

      {
        title: 'Historique  par Technicien ',
        icon: 'home-outline',
        link: '/pages/historyUser',
      },

      {
        title: 'Historique  par Ascenseur ',
        icon: 'home-outline',
        link: '/pages/historyLift',
      },

    ]
  },
  {
    title: 'Deconnexion',
    icon: 'log-in-outline',
    link: '(click)="logout()"'
  }

];
