<div class="row h-100">
    <div class="col-md-5">
        <div class="box box-info collapsed-box">
            <div class="box-header with-border">
                <ngx-bar-chart></ngx-bar-chart>
            </div>
        </div>

        <br/>
        
        <div class="box box-info collapsed-box">
            <div class="box-header with-border">
                <ngx-bar2-chart></ngx-bar2-chart>
            </div>
        </div>
    </div>

    <div class="col-md-7">
        <ngx-leaflet></ngx-leaflet>
    </div>
</div>