import { Component } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { LayoutService } from 'src/app/@core/utils/layout.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.component.html',
})
export class OneColumnLayoutComponent {

  constructor(
    private sidebarService: NbSidebarService, private layoutService: LayoutService
  ) { }

  dostaff() {
    this.sidebarService.collapse('menu-sidebar');
  }
}
