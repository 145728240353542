import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { DataManagementService } from '../data-management/data-management.service';

export const MY_DATE_FORMATS = {

  parse: {
    dateInput: 'DD/MM/YYYY HH:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY HH:mm',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },

};

@Component({
  selector: 'ngx-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class HistoryComponent implements OnInit {
  start: Date = new Date();
  end: Date = new Date();

  checked = true;
  title = 'appBootstrap';
  closeResult: string;

  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      date: {
        title: 'Date',
        type: 'date',
        filter: true,
        valuePrepareFunction: time => {
          let newValue = this.datePipe.transform(time, 'dd/MM/yyyy HH:mm:ss');
          return newValue;
        }
      },
      status: {
        title: 'Status',
        filter: {
          type: 'list',
          config: {
            selectText: 'Tous...',
            list: [
              { value: '0', title: 'Programme' },
              { value: '1', title: 'En Cours' },
              { value: '2', title: 'En Service' },
              { value: '3', title: 'En Arrêt' },
              { value: '4', title: 'Bloqué' },
            ],
          },
        },
        type: 'html',
        valuePrepareFunction: (value) => {
          if (value == 0) {
            return '<div class="text-nowrap text-danger"><i class="fas fa-check-circle"> </i> Programme </div>';
          } else if (value == 1) {
            return '<div class="text-nowrap text-info"><i class="fas fa-check-circle"> </i> En Cours </div>';
          } else if (value == 2) {
            return '<div class="text-nowrap text-success"><i class="fas fa-check-circle"> </i> En Service </div>';
          } else if (value == 3) {
            return '<div class="text-nowrap text-muted"><i class="fa fa-exclamation-circle"></i> En Arrêt</div>';
          } else {
            return '<div class="text-nowrap text-bloque"><i class="fa fa-exclamation-circle"></i> Bloqué</div>';
          }
        },
      },
      username: {
        title: 'Technicien',
        type: 'string',
        filter: true
      },
      name: {
        title: 'Ascenseur',
        type: 'number',
        filter: true,
        valuePrepareFunction: (value) => {
          return value ? value.split('/')[0] : value;
        }
      },
      description: {
        title: 'Description',
        type: 'number',
        filter: true
      },
      type: {
        title: 'Type',
        type: 'number',
        filter: true
      },
    }
  };
  source: LocalDataSource = new LocalDataSource();
  loading: boolean;
  customRanges: any;


  constructor(private datePipe: DatePipe, private dataManagementService: DataManagementService, private modalService: NgbModal) {
    this.dataManagementService.init();
   }

  ngOnInit(): void {
    this.customRanges = [
      {
        text: 'Today', desc: 'Today', value: 'today',
        start: moment().hours(0).minutes(0).seconds(0),
        end: moment(),
        default: true
      },
      {
        text: 'Yesterday', desc: 'Yesterday', value: 'last3yesterdaymonth',
        start: moment().subtract(1, 'day').hours(0).minutes(0).seconds(0),
        end: moment().subtract(1, 'day').hours(23).minutes(59).seconds(59),
      },
      {
        text: 'Last Month', desc: 'Last Month', value: 'lastmonth',
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month')
      },
      {
        text: 'Last 6 Month', desc: 'Last Month', value: 'lastmonth',
        start: moment().subtract(6, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month')
      },
      {
        text: 'This Year', desc: 'This Year', value: 'thisyear',
        start: moment().startOf('year'),
        end: moment().endOf('year')
      },
      {
        text: 'Last Year', desc: 'Last Year', value: 'lastyear',
        start: moment().subtract(1, 'year').startOf('year'),
        end: moment().subtract(1, 'year').endOf('year')
      }
    ];

    this.refresh();
  }

  refresh() {
    this.loading = true;
    this.dataManagementService.maintenanceDateDtos = [];
    this.dataManagementService.findAllMaintenancesByDate({
      startDate: new Date(this.start).setHours(0, 0, 0, 0),
      endDate: new Date(this.end).setHours(23, 59, 59, 0)
    }).subscribe(maintenanceDateDto => {

      maintenanceDateDto.forEach(element => {
        element.username = this.dataManagementService.getUserIdByNameUser(element.userId)
      });

      this.dataManagementService.maintenanceDateDtos = maintenanceDateDto;

      this.source.load(this.dataManagementService.maintenanceDateDtos);
      this.loading = true;
    }, () => {
      this.loading = false;
    })
  }


}
