import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../data/data';
import { SigninService } from '../signin/signin.service';

import { MENU_ITEMS } from './pages-menu';

@Component({
  selector: 'app-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})

export class PagesComponent {
  public currentUser: User = null;

  constructor(public signinService: SigninService, private router: Router) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
  }

  menu = MENU_ITEMS;

  logout() {
    this.signinService.logout().subscribe(
      resp => {
        localStorage.removeItem("token");
        this.router.navigate(["/"]);
      }
    );

  }

}
