import { Component, Input, OnInit } from '@angular/core';
import { DefaultEditor, ViewCell } from 'ng2-smart-table';

@Component({
  selector: 'ngx-calandier-datapicker',
  templateUrl: './calandier-datapicker.component.html',
  styleUrls: ['./calandier-datapicker.component.scss']
})
export class CalandierDatapickerComponent extends DefaultEditor implements OnInit {


  @Input() placeholder: string = 'Choose a Date';

  @Input() min: Date; // Defaults to now(rounded down to the nearest 15 minute mark)

  @Input() max: Date; // Defaults to 1 month after the min

  stringValue;
  inputModel: Date;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  onChange() {
    if (this.inputModel) {
      this.cell.newValue = this.inputModel.toISOString();
    }
  }
}

@Component({
  template: `{{value }} `,
})
export class SmartTableDatepickerRenderComponent implements ViewCell, OnInit {
  @Input() value: string;
  @Input() rowData: any;

  constructor() { }

  ngOnInit() { }

}